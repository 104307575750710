import React, { useState, useEffect } from "react";
import { useMutation, useQuery, QueryClient } from "react-query";
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import { Container, Stack, Typography, Card, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';

import plusCircleOutline from '@iconify/icons-eva/plus-circle-outline';
import closeCircleOutline from '@iconify/icons-eva/close-circle-outline';

import Page from 'components/page';
import { getSetting, saveSetting } from "services/setting.service";
import { formatDecimal, checkDecimal } from "utils/formatNumber";

import { RewardBox, StyledIconButton } from "./styled";

const Setting = () => {
  const queryClient = new QueryClient();

  const { isSuccess, data } = useQuery(["setting"], getSetting);

  const [setting, setSetting] = useState({
    backgroundImage: "",
    logo: "",
    ticketSetting: {
      prefix: "",
      length: 10,
    },
    fortunePrices: []
  });

  useEffect(() => {
    if (isSuccess && data) {
      setSetting(data);
    }
  }, [isSuccess, data]);

  // on submit
  const submitSetting = useMutation(values => {
    return saveSetting({values});
  });

  const onSubmitSetting = (values) => {
    const formValues = new FormData();
    formValues.append("siteName", values.siteName ? values.siteName : "");
    formValues.append("fortunePrices", JSON.stringify(values.fortunePrices));
    formValues.append("ticketSetting", JSON.stringify(values.ticketSetting));
    formValues.append("enableAudio", values.enableAudio ? values.enableAudio : false);

    if (values._id) {
      formValues.append("_id", values._id);
    }

    submitSetting.mutate(formValues, {
      onSuccess: (res) => {
        if (res)
          queryClient.setQueriesData(["setting"], res)
      },
      onSettled: () => {
        queryClient.invalidateQueries(["setting"]);
      }
    });
  }

  // formik
  const formik = useFormik({
    initialValues: setting,
    enableReinitialize: true,
    onSubmit: (values) => onSubmitSetting(values)
  });

  const { 
    values, 
    handleSubmit, 
    getFieldProps, 
    setFieldValue,
  } = formik;

  // other handle function
  const addReward = (arrayHelpers, index) => {
    arrayHelpers.unshift({
      reward: "",
      probabilityWin: index === 0 ? "100" : "0"
    });
  }

  const removeReward = (arrayHelpers, index) => {
    const removedValue = formatDecimal(getFieldProps(`fortunePrices.${index}.probabilityWin`)?.value ?? 0);
    const lastValue = formatDecimal(getFieldProps(`fortunePrices.${values.fortunePrices.length - 1}.probabilityWin`)?.value ?? 0);
    setFieldValue(`fortunePrices.${values.fortunePrices.length - 1}.probabilityWin`, (lastValue + removedValue).toString());

    arrayHelpers.remove(index);
  }

  const reCaculateLastValue = (currValue, curIdx) => {
    let value = currValue ? formatDecimal(currValue) : 0;
    values.fortunePrices.map((reward, index) => {
      if (index !== values.fortunePrices.length - 1 && curIdx !== index) {
        value += formatDecimal(reward.probabilityWin);
      }

      return false;
    });

    setFieldValue(`fortunePrices.${values.fortunePrices.length - 1}.probabilityWin`, (100 - value).toString());
  }

  const changeRatio = (value, index) => {
    if (value === "") {
      setFieldValue(`fortunePrices.${index}.probabilityWin`, 0); 
      reCaculateLastValue(value, index);
    } else {
      if (checkDecimal(value)) {
        setFieldValue(`fortunePrices.${index}.probabilityWin`, value); 
        reCaculateLastValue(value, index);
      }
    }
  }

  return (
    <Page title="Setting">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Setting
          </Typography>
        </Stack>

        <Card>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3} sx={{m: 2}}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>        
                  <FieldArray 
                    name="fortunePrices"
                    render={arrayHelpers => (
                      <div>
                        <StyledIconButton onClick={() => addReward(arrayHelpers, values?.fortunePrices?.length)}>
                          <Icon icon={plusCircleOutline} width={20} height={20} />
                          Add Reward
                        </StyledIconButton>

                        {
                          values?.fortunePrices?.map((reward, index) => (
                            <RewardBox key={index} className="mt-2">
                              <StyledIconButton className="icon-remove" onClick={() => removeReward(arrayHelpers, index)}>
                                <Icon icon={closeCircleOutline} width={20} height={20} />
                              </StyledIconButton>
                              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} className="mb-2">
                                <TextField
                                  fullWidth
                                  label="Reward"
                                  {...getFieldProps(`fortunePrices.${index}.reward`)}
                                />
                                <TextField
                                  fullWidth
                                  label="Probability Win (%)"
                                  onChange={(e) => changeRatio(e.target.value, index)}
                                  disabled={values.fortunePrices.length - 1 === index}
                                  step="0.01"
                                  value={reward.probabilityWin}
                                />
                              </Stack>
                            </RewardBox>
                          ))
                        }
                      </div>
                    )}
                  />
                </Stack>
              </Stack>
                <LoadingButton
                  sx={{m: 2}}
                  style={{display: "block", marginBottom: "380px"}}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
    </Page>
  );
}

export default Setting;