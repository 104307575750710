import React from "react";
import PropTypes from 'prop-types';
import { Typography, Stack, TextField } from '@mui/material';

const GeneralSetting = ({
  getFieldProps
}) => {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        General Setting
      </Typography>
      <Stack spacing={3} sx={{m: 2}}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            fullWidth
            label="Site Name"
            {...getFieldProps('siteName')}
          />
        </Stack>
      </Stack>
    </React.Fragment>
  );
}

GeneralSetting.propTypes = {
  getFieldProps: PropTypes.func,
};

GeneralSetting.defaultProps = {
  getFieldProps: () => {}
};

export default GeneralSetting;
