import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Login from 'pages/admin/login';
// admin user
import AdminUser from "pages/admin/adminUser";
import CreateAdminUser from "pages/admin/adminUser/Create";
import EditAdminUser from "pages/admin/adminUser/Edit";
// user
import User from "pages/admin/user";
import CreateUser from "pages/admin/user/Create";
import EditUser from "pages/admin/user/Edit";
// ticket
import Ticket from "pages/admin/ticket";
// import CreateTicket from "pages/admin/ticket/Create";
// import EditTicket from "pages/admin/ticket/Edit";
// ticket log
import TicketLog from "pages/admin/ticketLog";
// Reward
import Reward from "pages/admin/reward";
// Seting
import Setting from "pages/admin/setting";
import ChangePassword from "pages/admin/changepassword";

// user side
import Home from 'pages/user/home';
// import UserTurnover from 'pages/user/turnover';

import ProtectedRoute from "./protected.route";
import UserRoute from "./user.route";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/admin/login" component={Login} />

        {/* admin */}
        <ProtectedRoute exact path="/admin" component={AdminUser} />
        <ProtectedRoute exact path="/admin/adminUser" role="admin" component={AdminUser} />
        <ProtectedRoute exact path="/admin/adminUser/create" role="admin" component={CreateAdminUser} />
        <ProtectedRoute exact path="/admin/adminUser/edit/:id" role="admin" component={EditAdminUser} />
        {/* user */}
        <ProtectedRoute exact path="/admin/user" role="admin" component={User} />
        <ProtectedRoute exact path="/admin/user/create" role="admin" component={CreateUser} />
        <ProtectedRoute exact path="/admin/user/edit/:id" role="admin" component={EditUser} />
        {/* ticket */}
        <ProtectedRoute exact path="/admin/ticket" role="admin" component={Ticket} />
        {/* <ProtectedRoute exact path="/admin/ticket/create" role="admin" component={CreateTicket} />
        <ProtectedRoute exact path="/admin/ticket/edit/:id" role="admin" component={EditTicket} /> */}
        {/* ticket log */}
        <ProtectedRoute exact path="/admin/ticketlog" role="admin" component={TicketLog} />
        {/* reward */}
        <ProtectedRoute exact path="/admin/reward" role="admin" component={Reward} />
        {/* setting */}
        <ProtectedRoute exact path="/admin/setting" role="admin" component={Setting} />
        <ProtectedRoute exact path="/admin/changepassword" role="admin" component={ChangePassword} />

        {/* user side */}
        <UserRoute exact path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default AppRouter;