import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import uploadOutline from '@iconify/icons-eva/upload-outline';
import downloadOutline from '@iconify/icons-eva/download-outline';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/page';
import Scrollbar from 'components/scrollBar';
import DatatTableAdmin from 'components/dataTableAdmin';
import ModalConfirmation from 'components/modalConfirmation';
import UserMoreMenu from 'components/userMoreMenu';
// services
import { getUsers, deleteUser, importUser } from "services/user.service";
import { exportXlsx } from "utils/common";
import { fExportDate } from "utils/formatTime";

export default function User() {
  const history = useHistory();
  const { isSuccess, data } = useQuery(["users"], getUsers);

  const [users, setUsers] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setUsers(data);
    }
  }, [isSuccess, data]);

  const columnUsers = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "Username",
        selector: row => row.userName,
        sortable: true,
      },
      {
        name: "Contact No",
        selector: row => row.contactNo,
        sortable: true,
      },
      {
        name: "ID",
        selector: row => row.code,
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/admin/user/edit/${row._id}`)}
            onDelete={() => {
              setDeletedId(row._id);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceUsers = useMemo(() => {
    return users;

  }, [users]);

  const onExport = () => {
    const sheetUsers = users.map(x => {
      return {
        Username: x.userName,
        [`Contact No`]: x.contactNo,
        ID: x.code
      }
    });

    exportXlsx(sheetUsers, "Sheet1", `Users ${fExportDate(new Date())}`);
  }
  
  const handleUploadService = useMutation(values => {
    return importUser({values});
  });

  const onUpload = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    handleUploadService.mutate(formData, {
      onSuccess: (res) => {
        if (res) {
          setUsers([...users, ...res]);
        }
      }
    });
  }

  const handleDelete = useMutation(deleteId => {
    return deleteUser({deleteId});
  });

  const onDelete = () => {
    handleDelete.mutate(deleteId, {
      onSuccess: () => {
        setUsers(users.filter(x => x._id !== deleteId));
        setShow(false);
      }
    });
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <div>
            <Button
              variant="contained"
              startIcon={<Icon icon={downloadOutline} />}
              className="mr-2"
              onClick={() => onExport()}
            >
              Export
            </Button>
            <label htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: 'none' }}
                type="file"
                onChange={e => onUpload(e)}
              />
              <Button
                variant="contained"
                startIcon={<Icon icon={uploadOutline} />}
                component="span"
                className="mr-2"
              >
                Import
              </Button>
            </label>
            {/* <Button
              variant="contained"
              startIcon={<Icon icon={uploadOutline} />}
              className="mr-2"
            >
              Import
            </Button> */}
            <Button
              variant="contained"
              component={RouterLink}
              to="/admin/user/create"
              startIcon={<Icon icon={plusFill} />}
            >
              New User
            </Button>
          </div>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <DatatTableAdmin
                columns={columnUsers}
                data={sourceUsers}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => onDelete()}
        />
      </Container>
    </Page>
  );
}
