import React from "react";
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Card, Stack, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Page from 'components/page';
import { MHidden } from 'components/@material-extend';
import { login } from "services/auth.service";

import LoginForm from "./LoginForm";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const history = useHistory();

  const handleLogin = useMutation(values => {
    return login(values);
  });

  const onSubmit = (values) => {
    handleLogin.mutate(values, {
      onSuccess: (res) => {
        const { token } = res;
        localStorage.setItem("accessToken", token);
        localStorage.setItem("currentUser", JSON.stringify(res));
        history.push("/admin/adminUser");
      }
    });
  }

  return (
    <RootStyle title="Login">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to Andara77
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          <LoginForm onSubmit={onSubmit} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
