import React, { useState } from "react";
import PropTypes from "prop-types";
import { 
  TextField, 
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import styled from "styled-components";

import { fDate } from "utils/formatTime";

const TicketTextField = styled(TextField)`
  .MuiInputLabel-shrink {
    padding-left: 0px !important;
  }
`

const ModalForm = ({
  open,
  users,
  handleClose,
  handleOk,
  props
}) => {
  const [userName, setUserName] = useState("");
  // const [userId, setUserId] = useState("");
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        {"Create Ticket"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div style={{width: "250px", marginTop: "5px"}}>
          {/* <TextField
              select
              fullWidth
              label="User"
              value={userId}
              onChange={e => setUserId(e.target.value)}
            >
              {
                users.map((user, index) => (
                  <MenuItem key={index} value={user._id}>
                    {user.userName}
                  </MenuItem>
                ))
              }
            </TextField> */}
            <TicketTextField
              fullWidth
              className="ml-0"
              label="Username"
              value={userName}
              onChange={e => setUserName(e.target.value)}
            />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button 
          onClick={() => {
            setUserName("");
            handleOk({
              userName: userName,
              startDate: fDate(new Date()),
              endDate: fDate(new Date())
            });
          }} 
          autoFocus 
          disabled={userName === ""}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalForm.propTypes = {
  open: PropTypes.bool,
  users: PropTypes.array,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
};

ModalForm.defaultProps = {
  open: false,
  users: [],
  handleClose: () => {},
  handleOk: () => {},
};

export default ModalForm;
