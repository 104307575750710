import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import styled from "styled-components";

import ModalImg from "assets/modal.png";

const StyledModal = styled(Modal)`
  .MuiBackdrop-root {
    background: none;
  }

  img {
    width: 120px;
    text-align: center;
    margin: auto;
  }

  .MuiTypography-h6 {
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 14px;
    border-radius: 20px;
    padding: 10px 0px;
    font-weight: 600;
    background: #81a660;
    background: -webkit-linear-gradient(0deg, #81a660 0%, #d7c000 100%);
    background: linear-gradient(0deg, #81a660 0%, #d7c000 100%);
    -webkit-box-shadow: 0 0 10px 2px #ffdb93;
    -moz-box-shadow: 0 0 10px 2px #ffdb93;
    box-shadow: 0 0 10px 2px #ffdb93;
    color: white;

    span {
      display: block;
    }

    @media (max-width: 500px) {
      font-size: 12px;
    }
  }

  .MuiBox-root {
    border-radius: 15px;
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
  }
`;

const StyledBox = styled(Box)`
  -webkit-box-shadow: 0 0 10px 2px #ffdb93;
  -moz-box-shadow: 0 0 10px 2px #ffdb93;
  box-shadow: 0 0 10px 2px #ffdb93;

  @media (max-width: 500px) {
    width: 300px;
  }
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  height: 400,
  boxShadow: 24,
  backgroundSize: "cover",
  display: "flex",
  alignItems: "end",
  p: 4,
};

const ModalReward = ({
  open,
  setOpen,
  setSelectedReward,
  setPickRewardIdx,
  reward,
}) => {
  const handleClose = () => {
    setSelectedReward(null);
    setPickRewardIdx(null);
    setOpen(!open);
  }

  return (
    <StyledModal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox sx={style} style={{backgroundImage: `url(${ModalImg})`}}>
        <div style={{width: "100%"}}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span style={{fontSize: "26px"}}>{reward}</span>
            <span>{`Selamat anda telah mendapatkan ${reward}`}</span>
            <span>{`Silakan screenshot dan hubungi layanan pelanggan Anda`}</span>
          </Typography>
        </div>
      </StyledBox>
    </StyledModal>
  );
}

export default ModalReward;