import styled, { keyframes } from 'styled-components';
import Page from "components/page";
import { tada } from "react-animations";
import FlipMove from 'react-flip-move';

const TadaAnimation = keyframes`${tada}`;

export const TadaDiv = styled.div`
  animation: infinite 1s ${TadaAnimation};
`;

export const StyledPage = styled(Page)`
  height: 100%;

  > div,
  .MuiBox-root,
  .MuiGrid-root {
    height: 100%;
  }

  > div {
    display: flex;
  }

  .content-wrapper {
    padding: 25px 0px;
    display: block;
    margin: auto;
    align-items: center;
    justify-content: center;
    border: 0px;
    border-radius: 50px;

    .logo img, .logo {
      height: 100px;
      margin: auto;

      @media (max-width: 550px) {
        height: 75px;
      }
    }

    @media (max-width: 1000px) {
      width: 100%;
      padding: 25px;
    }
  }

  .fortune-wrapper {
    margin-top: 20px;

    .image-wrapper {
      display: flex;
      text-align: center;
      margin-top: 25px;
      padding-left: 10px;
      padding-right: 10px;
    }

    img {
      width: 100%;
      height: 180px;
      object-fit: contain;
      transition: transform .5s ease;

      @media (max-width: 1000px) {
        height: 100%;
      }
    }

    .pick-reward {
      cursor: pointer;
    }

    img.pick-reward:hover {
      transform: scale(1.2);
    }

    .inside-fortune {
      position: relative;
      top: -80px;
    }

    .MuiGrid-item {
      padding-top: 0px;
    }

    .hover-reward {
      position: absolute;
      width: 260px;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        width: 110px;
        background: #81a660;
        background: -webkit-linear-gradient(0deg, #81a660 0%, #d7c000 100%);
        background: linear-gradient(0deg, #81a660 0%, #d7c000 100%);
        -webkit-box-shadow: 0 0 10px 2px #ffdb93;
        -moz-box-shadow: 0 0 10px 2px #ffdb93;
        box-shadow: 0 0 5px 1px #ffdb93;
        padding: 4px 0px;
        color: white;
        border-radius: 10px;
        position: relative;
        top: -10px;
        font-size: 14px;

        @media (max-width: 550px) {
          width: 70px;
          font-size: 10px;
          padding: 2px 0px;
          top: -30px;
        }
      }

      @media (max-width: 550px) {
        width: 150px;
        height: 145px;
      }
    }
  }

  .input-wrapper {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      color: #637381 !important;
    }

    input {
      background-color: white !important;
    }

    .MuiInputBase-root {
      border-radius: 10px;
      border-color: #d1422a8a !important;
      -webkit-box-shadow: 0 0 4.5px 0 #fff;
      -moz-box-shadow: 0 0 4.5px 0 #fff;
      box-shadow: 0 0 4.5px 0 #fff;
    }

    button {
      min-width: 100px;
      margin-left: 20px;
      height: 45px;
      background-color: #64ba68;
      color: white !important;
    }

    .show-btn {
      background-color: #d3a618;
    }

    @media (max-width: 550px) {
      display: block;
      text-align: center;

      .MuiFormControl-root {
        width: 100%;
        display: block;
        margin-bottom: 25px;
      }

      .MuiInputBase-root {
        width: 100%;
      }
    }
  }
`;

export const GridFlipMove = styled(FlipMove)`
  display: grid;
  grid-template-columns: repeat(4, 260px);

  @media (max-width: 1000px) {
    grid-template-columns: repeat(4, 25%);
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(2, 150px);
    justify-content: center;
    position: relative;
  }
`;

export const StyledReward = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
`;

export const AudioWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;

  svg {
    color: white;
    font-size: 30px;
    cursor: pointer;
  }
`;

export const WarningMessage = styled.div`
  width: 50%;
  background-color: #faf3c0;
  padding: 10px;
  margin: auto;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;

  @media (max-width: 550px) {
    font-size: 16px;
    margin-top: 20px;
  }

  svg {
    position: relative;
    top: 3px;
    left: -8px;

    @media (max-width: 550px) {
      top: 15px;
    }
  }

  svg path {
    color: #ca3333;
  }
`;

export const StyledButton = styled.div`
  display: flex;
  gap: 20px;
  position: absolute;
  top: 20px;
  right: 20px;

  button {
    background-color: #f15221;
    -webkit-box-shadow: 1px 1px 10px 1px #717171;
    -moz-box-shadow: 1px 1px 10px 1px #717171;
    box-shadow: 1px 1px 10px 1px #717171;
  }

  button:hover {
    background-color: #f15221;
    -webkit-box-shadow: 1px 1px 10px 1px #717171;
    -moz-box-shadow: 1px 1px 10px 1px #717171;
    box-shadow: 1px 1px 10px 1px #717171;
  }
`;