import React from "react";
import { Route } from "react-router-dom";

import UserLayout from 'layouts/user';

const UserRoute = ({ component: Component, ...rest }) => {
  return (
    <Route 
      {...rest}

      render={(props) => {
        return (
          <UserLayout>
            <Component {...props} />
          </UserLayout>
        )
      }}
    />
  )
}

export default UserRoute;