import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const login = async payload => {
  try {
    const res = await axiosReq.post(`/api/auth/login`, payload);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}