import React from "react";
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMutation } from "react-query";
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { updateUserPassword } from "services/adminUser.service";

const ChangePasswordForm = ({
  initialValues,
}) => {
  const jsonUser = JSON.parse(localStorage.getItem("currentUser"));
  const id = jsonUser?._id;

  const PasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string().required('New Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required'),
	});
	
	const checkValidityPass = (values) => {
    const errors = {};

    if (!errors.newPassword && !errors.confirmPassword) {
      if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = "Password not match with new password";
      }
		}
		
    return errors;
  }

  const submitUser = useMutation(values => {
    return updateUserPassword({values, userId: id});
  });

  const onSubmit = (values) => {
    submitUser.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          console.log(res);
        }
      },
    });
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
		validationSchema: PasswordSchema,
		validate: checkValidityPass,
    onSubmit: (values) => onSubmit(values)
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Current Password"
              type="password"
              {...getFieldProps('currentPassword')}
              error={Boolean(touched.currentPassword && errors.currentPassword)}
              helperText={touched.currentPassword && errors.currentPassword}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="New Password"
              type="password"
              {...getFieldProps('newPassword')}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Confirm Password"
              type="password"
              {...getFieldProps('confirmPassword')}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
          </Stack>
        </Stack>
        <LoadingButton
          sx={{m: 2}}
          style={{display: "block"}}
          size="large"
          type="submit"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

ChangePasswordForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

ChangePasswordForm.defaultProps = {
  initialValues: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  },
  onSubmit: () => {}
};

export default ChangePasswordForm;
