import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getUsers = async () => {
  try {
    const res = await axiosReq.get(`/api/admin/adminUser`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getUser = async ({ queryKey }) => {
  const { userId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/adminUser/${userId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const createUser = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/adminUser/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const updateUser = async ({values, userId}) => {
  try {
    const res = await axiosReq.put(`/api/admin/adminUser/${userId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const updateUserPassword = async ({values, userId}) => {
  try {
    const res = await axiosReq.put(`/api/admin/adminUser/password/${userId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const deleteUser = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/adminUser/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}