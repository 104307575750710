import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/page';
import Scrollbar from 'components/scrollBar';
import DatatTableAdmin from 'components/dataTableAdmin';
import ModalConfirmation from 'components/modalConfirmation';
import UserMoreMenu from 'components/userMoreMenu';
import { fDate } from "utils/formatTime";
// services
import { getUsers, deleteUser } from "services/adminUser.service";

export default function User() {
  const history = useHistory();
  const { isSuccess, data } = useQuery(["adminUsers"], getUsers);

  const [users, setUsers] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setUsers(data);
    }
  }, [isSuccess, data]);

  const columnUsers = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "Username",
        selector: row => row.userName,
        sortable: true,
      },
      {
        name: "Created At",
        selector: row => fDate(row.createdAt),
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/admin/adminUser/edit/${row._id}`)}
            onDelete={() => {
              setDeletedId(row._id);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceUsers = useMemo(() => {
    return users;
  }, [users]);

  const handleDelete = useMutation(deleteId => {
    return deleteUser({deleteId});
  });

  const onDelete = () => {
    handleDelete.mutate(deleteId, {
      onSuccess: () => {
        setUsers(users.filter(x => x._id !== deleteId));
        setShow(false);
      }
    });
  }

  return (
    <Page title="Admin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Admin
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/admin/adminUser/create"
            startIcon={<Icon icon={plusFill} />}
          >
            New Admin
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <DatatTableAdmin
                columns={columnUsers}
                data={sourceUsers}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => onDelete()}
        />
      </Container>
    </Page>
  );
}
