import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Stack, Switch, FormControlLabel } from "@mui/material";
import styled from "styled-components";

import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";

const UploadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  cursor: pointer;
  background-size: cover;
  background-image: ${props => props.bgImage ? `url(${props.bgImage})` : "none"};

  svg {
    color: #637381;
  }

  img {
    position: absolute;
    width: 150px;
    height: 150px;
    object-fit: cover;
    z-index: -1;
  }
`;

const BgImageSetting = ({
  enableAudio,
  audioLink,
  bgImageBase64,
  bgMobileImage,
  bgLogoBase64,
  setFieldValue
}) => {
  const [previewImg, setPreviewImg] = useState(bgImageBase64);
  const [mobileImg, setMobileImg] = useState(bgMobileImage);
  const [previweLogo, setPreviewLogo] = useState(bgLogoBase64);

  const onUpload = (e, field) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        if (field === "fileBgImage") {
          setPreviewImg(reader.result);
        } else if (field === "fileMobileImage") {
          setMobileImg(reader.result);
        } else if (field === "fileLogo") {
          setPreviewLogo(reader.result);
        }
      }

      setFieldValue(field, file);
    }
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Homepage Background Image
      </Typography>
      <Stack spacing={3} sx={{m: 2}}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <label htmlFor="bg-home-image" className="border-image">
            <input
              id="bg-home-image"
              name="bg-home-image"
              style={{ display: 'none' }}
              type="file"
              onChange={e => onUpload(e, "fileBgImage")}
            />
            {
              previewImg ? (
                <UploadBox component="span">
                  <img src={previewImg} alt="bgimage" />
                  <Icon icon={plusFill} width={25} height={25} />
                </UploadBox>
              ) : (
                <UploadBox component="span">
                  <Icon icon={plusFill} width={25} height={25} />
                </UploadBox>
              )
            }
          </label>
        </Stack>
      </Stack>
      <Typography variant="h6" gutterBottom>
        Homepage Background Image - Mobile
      </Typography>
      <Stack spacing={3} sx={{m: 2}}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <label htmlFor="bg-mobile-image" className="border-image">
            <input
              id="bg-mobile-image"
              name="bg-mobile-image"
              style={{ display: 'none' }}
              type="file"
              onChange={e => onUpload(e, "fileMobileImage")}
            />
            {
              mobileImg ? (
                <UploadBox component="span">
                  <img src={mobileImg} alt="bgimage" />
                  <Icon icon={plusFill} width={25} height={25} />
                </UploadBox>
              ) : (
                <UploadBox component="span">
                  <Icon icon={plusFill} width={25} height={25} />
                </UploadBox>
              )
            }
          </label>
        </Stack>
      </Stack>
      <Typography variant="h6" gutterBottom>
        Logo Image (350w, 150h)
      </Typography>
      <Stack spacing={3} sx={{m: 2}}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <label htmlFor="logo-image" className="border-image">
            <input
              id="logo-image"
              name="logo-image"
              style={{ display: 'none' }}
              type="file"
              onChange={e => onUpload(e, "fileLogo")}
            />
            {
              previweLogo ? (
                <UploadBox component="span">
                  <img src={previweLogo} alt="bgimage" />
                  <Icon icon={plusFill} width={25} height={25} />
                </UploadBox>
              ) : (
                <UploadBox component="span">
                  <Icon icon={plusFill} width={25} height={25} />
                </UploadBox>
              )
            }
          </label>
        </Stack>
      </Stack>
      <Typography variant="h6" gutterBottom>
        Audio Setting
      </Typography>
      <Stack spacing={3} sx={{m: 2}}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <FormControlLabel onChange={(e) => setFieldValue("enableAudio", e.target.checked)} control={<Switch checked={enableAudio} />} label="Enable Audio" />
        </Stack>
      </Stack>
      <Stack spacing={3} sx={{m: 2}}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <span>{audioLink}</span>
          <label htmlFor="audio-file" className="border-image">
            <input
              id="audio-file"
              name="audio-file"
              type="file"
              onChange={e => onUpload(e, "fileAudio")}
            />
          </label>
        </Stack>
      </Stack>
    </React.Fragment>
  );
}

BgImageSetting.propTypes = {
  setFieldValue: PropTypes.func,
};

BgImageSetting.defaultProps = {
  setFieldValue: () => {}
};

export default BgImageSetting;
