import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getTicketLogs = async () => {
  try {
    const res = await axiosReq.get(`/api/admin/ticketlog`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getTicketLog = async ({ queryKey }) => {
  const { id } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/ticketlog/${id}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getTicketLogsByDate = async (startDate, endDate) => {
  try {
    const res = await axiosReq.get(`/api/admin/ticketlog/filter/date`, {
      params: {
        startDate: startDate,
        endDate: endDate
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const createTicketLog = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/ticketlog/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const updateTicketLog = async ({values, id}) => {
  try {
    const res = await axiosReq.put(`/api/admin/ticketlog/${id}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const importTicketLog = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/ticketlog/import`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const deleteTicketLog = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/ticketlog/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}