import styled from "styled-components";
import { IconButton } from '@mui/material';

export const StyledIconButton = styled(IconButton)`
  font-size: 16px;
  svg {
    margin-right: 6px;
  }

  :hover {
    background-color: inherit !important;
  }
`;

export const RewardBox = styled.div`
  border: 1px solid rgba(145, 158, 171, 0.32);
  padding: 15px;
  border-radius: 10px;

  > div {
    width: 800px;
  }

  .icon-remove {
    position: absolute;
    left: 830px;
    margin-top: -30px;
  }

  .popover {
    background-color: white;
  }
`;