import * as XLSX from "xlsx";

export const getLocalStorageUser = () => {
  let user = "";
  let currentUser = localStorage.getItem("currentUser");
  if (currentUser) {
    currentUser = JSON.parse(currentUser);
    user = currentUser;
  }

  return user;
}

export const exportXlsx = (values, sheetName, downloadName) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(values);
  XLSX.utils.book_append_sheet(wb, ws, sheetName ? sheetName : "Sheet1");
  XLSX.writeFile(wb, `${downloadName ? downloadName : "download"}.xlsx`)
}

export const generateString = (prefix = "", length = 10) => {
  let result = "";
  let numbers = "0123456789";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let numbersLength = numbers.length;
  let charactersLength = characters.length;

  for (let i = 0; i < length; i++ ) {
    if (i % 2 === 0) {
      result += numbers.charAt(Math.floor(Math.random() * numbersLength));
    } else {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  }

  return `${prefix}${result}`;
}

export const removeSpace = (value) => {
  if (!value) return "";
  
  return value.replace(/\s/g, '');
}

export const convertRgb = (value) => {
  if (!value) return "#fff";
  
  return `rgb(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
}

export const convertGraident = (gradients) => {
  let mergeColor = "";
  gradients?.map((gradient, index) => {
    if (gradients.length - 1 === index) {
      mergeColor += `${gradient.color}`;
    } else {
      mergeColor += `${gradient.color}, `;
    }
  })

  return `-webkit-linear-gradient(${mergeColor})`;
}

export const getBackendPath = (image) => {
  if (image) {
    return `${process.env.REACT_APP_API_URL}${image.replace("assets", "")}`;
  }

  return "";
}