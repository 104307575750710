import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { styled } from '@mui/material/styles';

import { getSetting } from "services/homepage.service";
import { getBackendPath } from "utils/common";

import { StyledBG } from "./styled";

const RootStyle = styled('div')({
  display: 'flex',
  overflow: 'auto',
  height: "100%"
});

const MainStyle = styled('div')({
  flexGrow: 1,
  minHeight: '100vh',
  height: "100%"
});

export default function UserLayout({children}) {
  const { isSuccess: isSuccessSetting, data: resSetting } = useQuery(["setting"], getSetting);
  const { innerWidth: width, innerHeight: height } = window;

  const [setting, setSetting] = useState(null);

  useEffect(() => {
    if (isSuccessSetting && resSetting) {
      setSetting(resSetting);
    }
  }, [isSuccessSetting, resSetting]);

  return (
    <RootStyle>
      {
        setting && (
          <React.Fragment>
            <MainStyle>
              <StyledBG>
                <img 
                  className="bgimage" 
                  src={getBackendPath(width <= 550 ? setting.bgImageMobile : setting.bgImageBase64)} 
                  alt="bgimage" 
                />
                {children}
              </StyledBG>
            </MainStyle>
          </React.Fragment>
        )
      }
    </RootStyle>
  );
}
