import React from "react";
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import styled from "styled-components";
import { Stack, TextField } from '@mui/material';

import { getBackendPath } from "utils/common";

import GeneralSetting from "./GeneralSetting";
import TicketSetting from "./TicketSetting";
import BgImageSetting from "./BgImageSetting";

const StyledBox = styled.div`
  margin-top: 15px;

  h6 {
    margin-left: 15px;
  }

  hr {
    margin: 25px 0px;
    height: 1px;
    background-color: #ddd;
    border: none;
  }
`;

const SettingForm = ({
  initialValues,
  onSubmit
}) => {
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values)
  });

  const { values, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <StyledBox>
          {/* <GeneralSetting getFieldProps={getFieldProps} />
          <hr /> */}

          <TicketSetting getFieldProps={getFieldProps} />
          <hr />

          <Stack spacing={3} sx={{m: 2}}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Daftar Link"
                {...getFieldProps('daftarLink')}
              />
              <TextField
                fullWidth
                label="Login Link"
                {...getFieldProps('loginLink')}
              />
            </Stack>
          </Stack>

          <BgImageSetting 
            enableAudio={values.enableAudio}
            audioLink={values.audio}
            bgImageBase64={getBackendPath(values.backgroundImage)} 
            bgMobileImage={getBackendPath(values.bgImageMobile)} 
            bgLogoBase64={getBackendPath(values.logo)}
            setFieldValue={setFieldValue} 
          />
          <hr />
        </StyledBox>  
        <LoadingButton
          sx={{m: 2, mb: 50}}
          style={{display: "block"}}
          size="large"
          type="submit"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

SettingForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

SettingForm.defaultProps = {
  initialValues: {
    backgroundImage: "",
    bgMobileImage: "",
    logo: "",
    daftarLink: "",
    loginLink: "",
    ticketSetting: {
      prefix: "",
      length: 10,
    },
    fortunePrices: []
  },
  onSubmit: () => {}
};

export default SettingForm;
