import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

import Page from 'components/page';
import { createUser } from "services/user.service";

import UserForm from "./UserForm";

const CrateUserForm = () => {
  const history = useHistory();

  const submitUser = useMutation(values => {
    return createUser({values});
  });

  const onSubmitUser = (values) => {
    submitUser.mutate(values, {
      onSuccess: (res) => {
        if (res)
          history.goBack();
      }
    });
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create User
          </Typography>
        </Stack>

        <Card>
          <UserForm 
            onSubmit={onSubmitUser}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default CrateUserForm;