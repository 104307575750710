import adminSvg from "assets/icons/admin.svg";
import userSvg from "assets/icons/user.svg";
import ticketSvg from "assets/icons/ticket.svg";
import ticketLogSvg from "assets/icons/ticketLog.svg";
import settingSvg from "assets/icons/setting.svg";
import rewardSvg from "assets/icons/reward.svg";

const adminSidebar = [
  {
    title: 'admin',
    path: '/admin/adminUser',
    icon: <img src={adminSvg} alt="adminSvg" />
  },
  // {
  //   title: 'user',
  //   path: '/admin/user',
  //   icon: <img src={userSvg} alt="userSvg" />
  // },
  {
    title: 'Reward',
    path: '/admin/reward',
    icon: <img src={rewardSvg} alt="userSvg" />
  },
  {
    title: 'ticket',
    path: '/admin/ticket',
    icon: <img src={ticketSvg} alt="ticketSvg" />
  },
  {
    title: 'Ticket Log',
    path: '/admin/ticketlog',
    icon: <img src={ticketLogSvg} alt="ticketLogSvg" />
  },
  {
    title: 'setting',
    path: '/admin/setting',
    icon: <img src={settingSvg} alt="settingSvg" />
  }
];

export default adminSidebar;
