import { replace } from "lodash";
import numeral from 'numeral';

const separatorList = {
  period: {
    name: "period",
    regex: /^\d+(\.\d{0,2})?$/
  }
};

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export const formatDecimal = (value) => {
  return Math.round(value * 100) / 100;
}

export const checkDecimal = (value) => {
  if (separatorList.period.regex.test(value)) {
    return true;
  } else {
    return false;
  }
};