import styled from "styled-components";
import { Typography } from '@mui/material';

export const StyledMenu = styled(Typography)`
  height: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
  color: ${props => props.color ? props.color : "#fff"};
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  font-family: STHeiti,Arial,"Microsoft Yahei","Hiragino Sans GB","Simsun,sans-self";

  span {
    display: block;
    text-align: center;
    min-width: 120px;
    letter-spacing: 0.2px;
    padding: 10px 18px;
    border-radius: 30px;
  }

  span.active, span:hover {
    background: ${props => props.hoverbgcolor ? props.hoverbgcolor : "#ddd"};
    color: ${props => props.hovercolor ? props.hovercolor : "#fff"};
  }
`

export const StyledNavbar = styled.div`
  background: ${props => props.image};
  background-color: ${props => props.bgColor};

  .MuiToolbar-root {
    width: 1080px;
    min-height: 100px;
    margin: auto;
  }

  img {
    height: 65px;
  }

  @media (max-width: 1200px) {
    padding: 0px 25px;

    .MuiToolbar-root {
      width: inherit;

      button {
        color: ${props => props.color};
      }
    }
  }
`;

export const ResponsiveLogo = styled.div`
  padding: 10px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledBG = styled.div`
  height: 100%;

  img.bgimage {
    position: absolute;
    z-index: -1;
    height: 100vh;
    width: 100%;
    object-fit: cover;

    @media (max-width: 550px) {
      height: 100%;
      object-fit: fill;
      min-height: 700px;
    }
  }
`