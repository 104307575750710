import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";

// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
  TextField,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Icon } from '@iconify/react';
import downloadOutline from '@iconify/icons-eva/download-outline';
import styled from "styled-components";
// components
import Page from 'components/page';
import Scrollbar from 'components/scrollBar';
import DatatTableAdmin from 'components/dataTableAdmin';
import { fDate, fExportDate, fDateTime } from "utils/formatTime";
import { exportXlsx } from "utils/common";
// services
import { getTicketLogs, getTicketLogsByDate } from "services/ticketLog.service";

const StyledFilterBox = styled.div`
  > div, button {
    margin-left: 15px;
  }
`;

export default function TicketList() {
  const { isSuccess, data } = useQuery(["ticketLogs"], getTicketLogs);

  const [ticketLogs, setTicketLogs] = useState([]);
  const [oriTicketLogs, setOriTicketLogs] = useState([]);
  const [filterDate, setFilterDate] = useState({});

  useEffect(() => {
    if (isSuccess) {
      setTicketLogs(data);
      setOriTicketLogs(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    let pass = true;

    if (!filterDate.startDate || !filterDate.endDate) {
      pass = false;
    }
    if (new Date(filterDate.endDate) < new Date(filterDate.startDate)) {
      pass = false;
    }

    if (pass) {
      getTicketLogsByDate(filterDate.startDate, filterDate.endDate)
      .then((res) => {
        setTicketLogs(res);
      });
    }
  }, [filterDate]);

  const columnTicketLogs = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "User",
        selector: row => row.ticketId?.userName,
        sortable: true,
      },
      {
        name: "Ticket No",
        selector: row => row.ticketId?.ticketNo,
        sortable: true,
      },
      {
        name: "Reward",
        selector: row => row.reward,
        sortable: true,
      },
      {
        name: "Date",
        selector: row => fDateTime(row.createdAt),
        sortable: true,
      }
    ]
  }, []);

  const sourceTicketLogs = useMemo(() => {
    return ticketLogs;

  }, [ticketLogs]);

  const onExport = () => {
    const sheetTickets = ticketLogs.map(x => {
      return {
        Username: x.ticketId?.userId?.userName,
        [`Ticket No`]: x.ticketId?.ticketNo,
        Reward: x.reward,
        Date: fDateTime(x.createdAt),
      }
    });

    exportXlsx(sheetTickets, "Sheet1", `Ticket-Log ${fExportDate(new Date())}`);
  }

  const handleChangeDate = (value, field) => {
    setFilterDate((prev) => {
      const prevValues = {...prev};
      prevValues[field] = value;

      return prevValues;
    });
  }

  console.log(filterDate)

  return (
    <Page title="Ticket Log">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Ticket Log
          </Typography>

          <StyledFilterBox>
            <TextField
              className="ml-0"
              placeholder="Search by Ticket No"
              onChange={e => setTicketLogs(oriTicketLogs.filter(x => x.ticketId?.ticketNo?.toLowerCase()?.includes(e.target.value.toLowerCase())))}
            />
            <DesktopDatePicker
              label="Start Date"
              inputFormat="dd/MM/yyyy"
              value={filterDate.startDate ? filterDate.startDate : null}
              onChange={(value) => handleChangeDate(fDate(value), "startDate")}
              renderInput={(params) => {
                return (
                  <TextField 
                    width={150}
                    {...params} 
                  />
                )
              }}
            />
            <DesktopDatePicker
              label="End Date"
              inputFormat="dd/MM/yyyy"
              value={filterDate.endDate ? filterDate.endDate : null}
              onChange={(value) => handleChangeDate(fDate(value), "endDate")}
              renderInput={(params) => {
                return (
                  <TextField 
                    width={150}
                    {...params} 
                  />
                )
              }}
            />
            <Button
              variant="contained"
              startIcon={<Icon icon={downloadOutline} />}
              className="mr-2"
              onClick={() => onExport()}
            >
              Export
            </Button>
          </StyledFilterBox>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <DatatTableAdmin
                columns={columnTicketLogs}
                data={sourceTicketLogs}
              />
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
