import React from "react";
import PropTypes from 'prop-types';
import { Typography, Stack, TextField } from '@mui/material';

const TicketSetting = ({
  getFieldProps
}) => {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Ticket
      </Typography>
      <Stack spacing={3} sx={{m: 2}}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            fullWidth
            label="Prefix"
            {...getFieldProps('ticketSetting.prefix')}
          />
          <TextField
            fullWidth
            label="Length"
            {...getFieldProps('ticketSetting.length')}
          />
        </Stack>
      </Stack>
    </React.Fragment>
  );
}

TicketSetting.propTypes = {
  getFieldProps: PropTypes.func,
};

TicketSetting.defaultProps = {
  getFieldProps: () => {}
};

export default TicketSetting;
