import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getTickets = async () => {
  try {
    const res = await axiosReq.get(`/api/admin/ticket`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getTicket = async ({ queryKey }) => {
  const { id } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/ticket/${id}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const createTicket = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/ticket/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const updateTicket = async ({values, id}) => {
  try {
    const res = await axiosReq.put(`/api/admin/ticket/${id}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const importTicket = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/ticket/import`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const deleteTicket = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/ticket/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}