// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// routes
import Router from "routes/app.route";
// components
import ScrollToTop from './components/scrollToTop';
import AlertMessage from "./components/alertMessage";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <Router />
      <AlertMessage />
    </ThemeConfig>
  );
}
