import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getUsers = async () => {
  try {
    const res = await axiosReq.get(`/api/admin/user`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const getUser = async ({ queryKey }) => {
  const { userId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/user/${userId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const createUser = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/user/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const updateUser = async ({values, userId}) => {
  try {
    const res = await axiosReq.put(`/api/admin/user/${userId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const importUser = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/user/import`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}

export const deleteUser = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/user/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Fail");
  }
}