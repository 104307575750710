import React, { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";

import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
  TextField,
} from '@mui/material';
// components
import Page from 'components/page';
import Scrollbar from 'components/scrollBar';
import DatatTableAdmin from 'components/dataTableAdmin';
import ModalConfirmation from 'components/modalConfirmation';
import UserMoreMenu from 'components/userMoreMenu';
import { fDate } from "utils/formatTime";

import ModalForm from "./ModalForm";
// services
import { 
  getTickets, 
  createTicket, 
  deleteTicket 
} from "services/ticket.service";
import { getUsers } from "services/user.service";

export default function TicketList() {
  const history = useHistory();

  const { isSuccess, data } = useQuery(["tickets"], getTickets);
  const { isSuccess: isSuccessUsers, data: resUsers } = useQuery(["users"], getUsers);

  const [stateUsers, setStateUsers] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [oriTickets, setOriTickets] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setTickets(data);
      setOriTickets(data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccessUsers) {
      setStateUsers(resUsers);
    }
  }, [isSuccessUsers, resUsers]);

  const columnTickets = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "User",
        selector: row => row.userName,
        sortable: true,
      },
      {
        name: "Ticket No",
        selector: row => row.ticketNo,
        sortable: true,
      },
      {
        name: "Used",
        selector: row => row.used ? "Yes" : "No",
        sortable: true,
      },
      {
        name: "Ticket Date",
        selector: row => row.endDate,
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            showEdit={false}
            onDelete={() => {
              setDeletedId(row._id);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceTickets = useMemo(() => {
    return tickets;

  }, [tickets]);

  const handleDelete = useMutation(deleteId => {
    return deleteTicket({deleteId});
  });

  const onDelete = () => {
    handleDelete.mutate(deleteId, {
      onSuccess: () => {
        setTickets(tickets.filter(x => x._id !== deleteId));
        setOriTickets(oriTickets.filter(x => x._id !== deleteId));
        setShow(false);
      }
    });
  }

  const submitTicket = useMutation(values => {
    return createTicket({values});
  });

  const onSubmitTicket = (values) => {
    submitTicket.mutate(values, {
      onSuccess: (res) => {
        console.log(res)
        if (res) {
          setShowForm(false);
          setTickets([res, ...tickets]);
          setOriTickets([res, ...oriTickets]);
        }
      }
    });
  }

  return (
    <Page title="Ticket">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Ticket
          </Typography>
          <TextField
            className="ml-0"
            placeholder="Search by Ticket No"
            onChange={e => setTickets(oriTickets.filter(x => x.ticketNo?.toLowerCase()?.includes(e.target.value.toLowerCase())))}
          />
          <Button
            variant="contained"
            component={RouterLink}
            onClick={() => setShowForm(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            New Ticket
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <DatatTableAdmin
                columns={columnTickets}
                data={sourceTickets}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => onDelete()}
        />

        <ModalForm 
          open={showForm}
          users={stateUsers}
          handleClose={() => setShowForm(false)}
          handleOk={(values) => onSubmitTicket(values)}
        />
      </Container>
    </Page>
  );
}
