import React from "react";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const AdminUserForm = ({
  initialValues,
  onSubmit
}) => {
  const UserSchema = Yup.object().shape({
    userName: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required'),
	});
	
	const checkValidityPass = (values) => {
    const errors = {};

    if (!errors.password && !errors.confirmPassword) {
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Password not match with new password";
      }
		}
		
    return errors;
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
		validationSchema: UserSchema,
		validate: checkValidityPass,
    onSubmit: (values) => onSubmit(values)
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Username"
              {...getFieldProps('userName')}
              error={Boolean(touched.userName && errors.userName)}
              helperText={touched.userName && errors.userName}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Password"
              type="password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              fullWidth
              label="Confirm Password"
              type="password"
              {...getFieldProps('confirmPassword')}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
          </Stack>
        </Stack>
          <LoadingButton
            sx={{m: 2}}
            style={{display: "block"}}
            size="large"
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

AdminUserForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

AdminUserForm.defaultProps = {
  initialValues: {
    userName: "",
    password: "",
    confirmPassword: ""
  },
  onSubmit: () => {}
};

export default AdminUserForm;
