import React, { useState, useEffect } from "react";
import { useMutation, useQuery, QueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

import Page from 'components/page';
import { getUser, updateUser } from "services/adminUser.service";

import AdminUserForm from "./AdminUserForm";

const EditAdminUserForm = () => {
  const history = useHistory();
  const queryClient = new QueryClient();

  const { id } = useParams();
  const { isSuccess: isSuccessUser, data: resUser } = useQuery(["admin-edit", { userId: id }], getUser);

  const [userState, setUserState] = useState({});

  useEffect(() => {
    if (isSuccessUser) {
      setUserState(resUser);
    }
  }, [isSuccessUser, resUser]);


  const submitUser = useMutation(values => {
    return updateUser({values, userId: id});
  });

  const onSubmitUser = (values) => {
    submitUser.mutate(values, {
      onSuccess: (res) => {
        if (res)
          queryClient.setQueriesData(["admin-edit", { userId: id }], res)
      },
      onSettled: () => {
        queryClient.invalidateQueries(["admin-edit", { userId: id }]);
        history.goBack();
      }
    });
  }

  return (
    <Page title="Admin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Admin
          </Typography>
        </Stack>

        <Card>
          {
            (isSuccessUser === true && !isEmpty(userState)) && (
              <AdminUserForm 
                initialValues={{
                  ...userState,
                  password: "",
                  confirmPassword: "",
                }}
                onSubmit={onSubmitUser}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditAdminUserForm;